.measurementsWrapper {
  max-height: 750px;
  border-radius: 5px;
  margin: auto;
  overflow: auto;
}

.measurements {
  width: 100%;
  border-spacing: 0;
  position: relative;
}

.measurements th {
  font-weight: normal;
  text-align: left;
  background: var(--blue-500);
  color: #fff;
  position: sticky;
  top: 0px;
  padding: 1rem;
}

.measurements td {
  padding: 1rem;
}

.measurements tr:nth-child(even) {
  background: #fff;
}

.measurements tr:nth-child(odd) {
  background: #f4f4f4;
}
