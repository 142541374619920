.helpContent {
  display: flex;
	flex-direction: column;
	margin-top: .5rem;
}

.helpContent > * {
	margin-bottom: .5rem;
}

.helpContent > *:last-child {
	margin-bottom: 0;
}
