.form {
  max-width: 800px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem 2rem;
  margin: auto;
  align-items: center;
}

@media (max-width: 650px){
  .form{
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
