.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--grey-500);
  font-size: 0.95rem;
  transition: opacity 0.3s;
}

.primary {
  color: white;
  background: var(--blue-500);
}

.primaryDisabled {
  --disabledButNotSubmittingOpacity: 0.5;
  opacity: var(--disabledButNotSubmittingOpacity);
  cursor: auto;
}

.primaryDisabledSubmitting {
  cursor: auto;
}

.icon{
  display: initial;
  border: none;
  background: transparent;
  padding: 0;
  font-size: initial;
}

.icon:focus{
  outline: none;
}

@media (any-hover: hover) {
  .primary:hover,
  .primaryDisabledSubmitting {
    opacity: 0.85;
  }

  .primaryDisabled:hover {
    opacity: var(--disabledButNotSubmittingOpacity);
  }
}
