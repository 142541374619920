:root {
  --blue-500: #4c4cff;
  --blue-800: #2d2d99;

  --green-500: #b3b300;

  --grey-500: #ccc;

  --red-500: #ff0000;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  line-height: 1.8;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

a {
  color: var(--blue-800);
}

