.input {
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  outline: none;
  font-size: 0.95rem;
  border: 1px solid var(--grey-500);
}

.input:focus {
  border: 1px solid var(--blue-500);
}
